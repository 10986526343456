import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../assets/images/logo.png';
import { FaBars, FaTimes } from 'react-icons/fa';

const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  background-color: ${props => props.isScrolled ? '#000000' : 'transparent'};
  box-shadow: ${props => props.isScrolled ? '0 2px 4px rgba(0,0,0,0.2)' : 'none'};
  transition: background-color 0.3s, box-shadow 0.3s;
  z-index: 1001;
  padding: 1rem 0;
  
  @media (max-width: 768px) {
    background-color: #000000;
    padding: 0.5rem 0;
  }
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  height: 80px;

  @media (min-width: 769px) {
    flex-direction: column;
    height: auto;
    gap: 2rem;
    padding: 1rem 2rem;
  }

  @media (max-width: 768px) {
    height: 60px;
  }
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 769px) {
    margin-bottom: 1rem;
  }

  @media (max-width: 768px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  img {
    height: 80px;
    transition: transform 0.3s ease;

    @media (max-width: 768px) {
      height: 50px;
    }

    &:hover {
      transform: scale(1.05);
    }
  }
`;

const MobileMenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  transition: color 0.3s ease;

  &:hover {
    color: #007bff;
  }

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 2rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1002;
  }
`;

const NavLinks = styled.div`
  display: flex;
  gap: 3rem;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    display: ${props => props.isOpen ? 'flex' : 'none'};
    flex-direction: column;
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    background-color: #000000;
    padding: 2rem;
    gap: 2rem;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    animation: ${props => props.isOpen ? 'slideDown 0.3s ease' : 'none'};
    z-index: 1000;
  }

  a {
    text-decoration: none;
    color: #ffffff;
    font-weight: 500;
    font-size: 1.2rem;
    position: relative;
    padding: 0.5rem 0;
    transition: color 0.3s ease;
    
    &::after {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 0;
      height: 2px;
      background-color: #007bff;
      transition: width 0.3s ease;
    }

    &:hover {
      color: #007bff;
      
      &::after {
        width: 100%;
      }
    }

    &.active {
      color: #007bff;
      
      &::after {
        width: 100%;
      }
    }
  }

  @keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const Overlay = styled.div`
  display: none;
  
  @media (max-width: 768px) {
    display: ${props => props.isOpen ? 'block' : 'none'};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 999;
  }
`;

function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <>
      <HeaderContainer isScrolled={isScrolled}>
        <Nav>
          <Logo>
            <Link to="/">
              <img src={logo} alt="Hidrogen Logo" />
            </Link>
          </Logo>
          <MobileMenuButton onClick={toggleMenu}>
            {isOpen ? <FaTimes /> : <FaBars />}
          </MobileMenuButton>
          <NavLinks isOpen={isOpen}>
            <Link to="/" className={isActive("/") ? "active" : ""}>
              Beranda
            </Link>
            <Link to="/fuel-water-hybrid" className={isActive("/fuel-water-hybrid") ? "active" : ""}>
              Fuel Water Hybrid
            </Link>
            <Link to="/hidrogenpasta" className={isActive("/hidrogenpasta") ? "active" : ""}>
              HidrogenPasta
            </Link>
            <Link to="/hidrofit" className={isActive("/hidrofit") ? "active" : ""}>
              Hidrofit
            </Link>
            <Link to="/kontak" className={isActive("/kontak") ? "active" : ""}>
              Kontak
            </Link>
          </NavLinks>
        </Nav>
      </HeaderContainer>
      <Overlay isOpen={isOpen} onClick={() => setIsOpen(false)} />
    </>
  );
}

export default Header;
