import React, { useState, useEffect, useRef, useCallback, memo } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { FaPlay, FaPause } from 'react-icons/fa';
import PropTypes from 'prop-types';
import passEngineeringLogo from '../assets/images/pass-engineering.png';

// Constants
const AUDIO_URL = 'https://dl.songsara.net/FRE/2021/1/The%20Best%20Of%20Kenny%20G%20(Playlist)/06%20Songbird.mp3';
const API_URL = 'https://api.hidrogen.id/api/hits';
const INITIAL_VOLUME = 0.5;

// Keyframes
const shimmer = keyframes`
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
`;

const pulse = keyframes`
  0% { transform: scale(1); opacity: 0.5; }
  50% { transform: scale(1.05); opacity: 0.7; }
  100% { transform: scale(1); opacity: 0.5; }
`;

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
`;

// Styled Components
const FooterContainer = styled.footer`
  background-color: #2d74b4;
  padding: 2rem 0;
  width: 100%;
  margin-top: -20px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, rgba(0,0,0,0.1) 0%, rgba(255,255,255,0.1) 100%);
    pointer-events: none;
  }
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  gap: 1.5rem;
  position: relative;
  animation: ${fadeIn} 0.6s ease-out;

  @media (max-width: 768px) {
    padding: 0 1rem;
    gap: 1rem;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-2px);
  }

  img {
    height: 60px;
    width: auto;
    transition: filter 0.3s ease;
    will-change: transform;
  }

  @media (max-width: 768px) {
    img {
      height: 50px;
    }
  }
`;

const EnhancedVisitorCounter = styled.div`
  color: white;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  background: rgba(255, 255, 255, 0.1);
  padding: 0.8rem 1.5rem;
  border-radius: 30px;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  overflow: hidden;
`;

const CounterBox = styled.div`
  background: linear-gradient(145deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1));
  padding: 0.5rem 1.2rem;
  border-radius: 20px;
  font-weight: bold;
  min-width: 90px;
  text-align: center;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.2);
  
  ${props => props.isLoading && css`
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: -45%;
      width: 40%;
      height: 100%;
      background: linear-gradient(
        90deg,
        transparent,
        rgba(255, 255, 255, 0.2),
        transparent
      );
      animation: ${shimmer} 1s infinite;
    }
  `}
`;

const GlowEffect = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle at center,
    rgba(255, 255, 255, 0.1) 0%,
    transparent 70%
  );
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;

  ${EnhancedVisitorCounter}:hover & {
    opacity: 1;
  }
`;

const PulseEffect = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background: rgba(255, 255, 255, 0.1);
  animation: ${pulse} 2s ease-in-out infinite;
  pointer-events: none;
`;

const AudioControl = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  padding: 12px;
  border-radius: 50px;
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  }
`;

const AudioButton = styled.button`
  background: rgba(255, 255, 255, 0.1);
  border: none;
  color: white;
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.3s ease;
  width: 40px;
  height: 40px;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
    transform: scale(1.1);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    transform: none;
  }

  span {
    font-size: 12px;
    white-space: nowrap;
  }
`;

// Custom hook for audio management with enhanced autoplay
const useAudioPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(true); // Start with true for autoplay attempt
  const [isLoading, setIsLoading] = useState(true);
  const audioRef = useRef(null);
  const hasInteracted = useRef(false);

  useEffect(() => {
    let mounted = true;
    let audio = new Audio(AUDIO_URL);
    audioRef.current = audio;

    const initializeAudio = () => {
      audio.loop = true;
      audio.volume = INITIAL_VOLUME;
      audio.preload = 'auto';

      // Audio event listeners
      audio.addEventListener('canplaythrough', () => {
        if (mounted) {
          setIsLoading(false);
          if (hasInteracted.current) {
            audio.play().catch(console.error);
          }
        }
      });

      audio.addEventListener('playing', () => {
        if (mounted) setIsPlaying(true);
      });

      audio.addEventListener('pause', () => {
        if (mounted) setIsPlaying(false);
      });

      audio.addEventListener('ended', () => {
        if (mounted) {
          audio.currentTime = 0;
          audio.play().catch(console.error);
        }
      });

      audio.addEventListener('error', (e) => {
        console.error('Audio error:', e);
        if (mounted) {
          setIsLoading(false);
          setIsPlaying(false);
        }
      });
    };

    const attemptAutoplay = async () => {
      try {
        await audio.play();
        hasInteracted.current = true;
      } catch (err) {
        console.log('Autoplay prevented. Waiting for user interaction.');
        setIsPlaying(false);
      }
    };

    const handleVisibilityChange = () => {
      if (!audio) return;
      
      if (document.hidden) {
        audio.pause();
      } else if (hasInteracted.current && isPlaying) {
        audio.play().catch(console.error);
      }
    };

    const handleUserInteraction = async () => {
      if (!hasInteracted.current && audio) {
        hasInteracted.current = true;
        try {
          await audio.play();
          setIsPlaying(true);
        } catch (err) {
          console.error('Playback failed:', err);
          setIsPlaying(false);
        }
      }
    };

    // Initialize audio
    initializeAudio();
    
    // Attempt autoplay
    attemptAutoplay();

    // Add event listeners
    document.addEventListener('visibilitychange', handleVisibilityChange);
    document.addEventListener('click', handleUserInteraction, { once: true });
    document.addEventListener('touchstart', handleUserInteraction, { once: true });
    document.addEventListener('keydown', handleUserInteraction, { once: true });

    // Cleanup function
    return () => {
      mounted = false;
      if (audio) {
        audio.pause();
        audio.src = '';
        audio.remove();
      }
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      document.removeEventListener('click', handleUserInteraction);
      document.removeEventListener('touchstart', handleUserInteraction);
      document.removeEventListener('keydown', handleUserInteraction);
    };
  }, []); // Empty dependency array as we want this to run once

  // Handle play/pause toggling
  const togglePlayPause = useCallback(() => {
    if (!audioRef.current || isLoading) return;

    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play().catch(err => {
        console.error('Playback failed:', err);
        setIsPlaying(false);
      });
    }
  }, [isPlaying, isLoading]);

  return { isPlaying, isLoading, togglePlayPause };
};

// Custom hook for visitor count
const useVisitorCount = () => {
  const [hits, setHits] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let mounted = true;

    const fetchHits = async () => {
      try {
        const response = await fetch(API_URL);
        if (!response.ok) throw new Error('Failed to fetch visitor count');
        const data = await response.json();
        if (mounted) {
          setHits(data?.count || data?.data?.count || 0);
          setIsLoading(false);
        }
      } catch (error) {
        if (mounted) {
          console.error('Error fetching hits:', error);
          setError(error.message);
          setIsLoading(false);
        }
      }
    };

    fetchHits();
    return () => { mounted = false; };
  }, []);

  return { hits, isLoading, error };
};

// Memoized components
const AnimatedCounter = memo(({ value }) => {
  const [displayValue, setDisplayValue] = useState(0);
  
  useEffect(() => {
    const duration = 1000;
    const steps = 20;
    const increment = (value - displayValue) / steps;
    let currentStep = 0;

    const timer = setInterval(() => {
      if (currentStep < steps) {
        setDisplayValue(prev => Math.round(prev + increment));
        currentStep++;
      } else {
        setDisplayValue(value);
        clearInterval(timer);
      }
    }, duration / steps);

    return () => clearInterval(timer);
  }, [value, displayValue]);

  return value > 0 ? displayValue.toLocaleString() : '0';
});

const Logo = memo(() => (
  <LogoContainer>
    <a 
      href="https://passengineering.id/"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Visit Pass Engineering website"
    >
      <img 
        src={passEngineeringLogo} 
        alt="Pass Engineering"
        loading="lazy"
        width="auto"
        height="60"
      />
    </a>
  </LogoContainer>
));

const PlayPauseButton = memo(({ isPlaying, isLoading, onClick }) => (
  <AudioButton 
    onClick={onClick}
    disabled={isLoading}
    aria-label={isPlaying ? 'Pause music' : 'Play music'}
    title={isLoading ? 'Loading...' : isPlaying ? 'Pause' : 'Play'}
  >
    {isLoading ? (
      <span>...</span>
    ) : isPlaying ? (
      <FaPause size={16} />
    ) : (
      <FaPlay size={16} />
    )}
  </AudioButton>
));

const VisitorCounter = memo(({ hits, isLoading, error }) => (
  <EnhancedVisitorCounter>
    <GlowEffect />
    {isLoading && <PulseEffect />}
    <span>Visitors</span>
    <CounterBox isLoading={isLoading}>
      {isLoading ? (
        <span>Loading...</span>
      ) : error ? (
        <span style={{ color: '#ff6b6b' }}>Error</span>
      ) : (
        <AnimatedCounter value={hits} />
      )}
    </CounterBox>
  </EnhancedVisitorCounter>
));

// PropTypes
AnimatedCounter.propTypes = {
  value: PropTypes.number.isRequired
};

PlayPauseButton.propTypes = {
  isPlaying: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

VisitorCounter.propTypes = {
  hits: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.string
};

// Main Footer Component
const Footer = () => {
  const { isPlaying, isLoading, togglePlayPause } = useAudioPlayer();
  const { hits, isLoading: hitsLoading, error } = useVisitorCount();

  return (
    <FooterContainer>
      <FooterContent>
        <Logo />
        <VisitorCounter 
          hits={hits}
          isLoading={hitsLoading}
          error={error}
        />
      </FooterContent>

      <AudioControl>
        <PlayPauseButton 
          isPlaying={isPlaying}
          isLoading={isLoading}
          onClick={togglePlayPause}
        />
      </AudioControl>
    </FooterContainer>
  );
};

export default memo(Footer);
