import React, { useCallback, memo } from 'react';
import styled, { keyframes } from 'styled-components';
import { FaDownload } from 'react-icons/fa';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
  width: 100%;
  background-color: #eef3f9;
`;

const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  animation: ${fadeIn} 1s ease-out;

  h2 {
    font-size: 2.2rem;
    color: #333;
    margin-bottom: 1.5rem;
  }
`;

const Card = styled.div`
  background-color: white;
  padding: 2.5rem;
  border-radius: 15px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
`;

const VideoWrapper = styled.div`
  margin-bottom: 2rem;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  border-radius: 10px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

const Header = styled.div`
  width: 100%;
  height: 400px;
  background-color: #000000;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  color: white;
  position: relative;
  padding-top: 80px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 150px;
    background: linear-gradient(to top, #eef3f9, transparent);
  }
`;

const HeaderContent = styled.div`
  max-width: 1200px;
  padding: 0 2rem;
  margin-top: 10rem;
  animation: ${fadeIn} 1.2s ease-out;
  position: relative;
  z-index: 2;

  h1 {
    font-size: clamp(2.8rem, 5vw, 4.5rem);
    margin-bottom: 1.5rem;
    font-weight: 800;
    color: #ffffff;
    letter-spacing: -1px;
    line-height: 1.2;
  }

  p {
    font-size: clamp(1.2rem, 2vw, 1.6rem);
    margin-bottom: 3rem;
    color: #cccccc;
    font-weight: 300;
    letter-spacing: 0.5px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
`;

const DownloadButton = styled.button`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 123, 255, 0.2);
  }

  &:active {
    transform: translateY(0);
  }

  .download-icon {
    transition: transform 0.3s ease;
  }

  &:hover .download-icon {
    transform: translateY(2px);
  }
`;

const Video = memo(({ url }) => (
  <VideoWrapper>
    <iframe
      title="YouTube Video"
      src={url}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      loading="lazy"
    />
  </VideoWrapper>
));

Video.displayName = 'Video';

const HidrogenPasta = () => {
  const handleDownload = useCallback(() => {
    const link = document.createElement('a');
    link.href = '/assets/media/HIDROGENPASTA.pdf';
    link.download = 'HIDROGENPASTA.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  return (
    <>
      <Header>
        <HeaderContent>
          <h1>HidrogenPasta</h1>
          <p>Informasi HidrogenPasta</p>
        </HeaderContent>
      </Header>

      <Container>
        <Content>
        <Card>
          <Video url="https://www.youtube.com/embed/s1YTKtjVnTs" />
          <h2>Hidrogen Pasta Documentation</h2>
          <ButtonWrapper>
            <DownloadButton
              onClick={handleDownload}
              aria-label="Download PDF document"
            >
              <FaDownload className="download-icon" />
              <span>Download PDF</span>
            </DownloadButton>
          </ButtonWrapper>
        </Card>
        </Content>
      </Container>
    </>
  );
};

export default memo(HidrogenPasta);
