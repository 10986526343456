import React from 'react';
import styled, { keyframes } from 'styled-components';

// Animation keyframes
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const HidroFitContainer = styled.div`
  width: 100%;
  background-color: #eef3f9;
`;

const HidroFitContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  animation: ${fadeIn} 1s ease-out;

  h3 {
    font-size: 2rem;
    margin-bottom: 2rem;
    color: #333;
    position: relative;

    &::after {
      content: '';
      display: block;
      width: 60px;
      height: 3px;
      background: #007bff;
      margin-top: 1rem;
    }
  }

  .content {
    background-color: white;
    padding: 2.5rem;
    border-radius: 15px;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
    
    .video {
      margin-bottom: 2rem;
      position: relative;
      padding-bottom: 56.25%; // 16:9 aspect ratio
      height: 0;
      overflow: hidden;
      border-radius: 10px;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
      }
    }

    .content-page {
      h2 {
        font-size: 2.2rem;
        color: #333;
        margin-bottom: 1.5rem;
      }

      h3 {
        font-size: 1.8rem;
        color: #444;
        margin: 2rem 0 1rem;
      }

      h4 {
        font-size: 1.5rem;
        color: #555;
        margin: 1.5rem 0 1rem;
      }

      p {
        font-size: 1.1rem;
        line-height: 1.8;
        color: #666;
        margin-bottom: 1.5rem;
      }

      ol {
        padding-left: 1.5rem;
        margin-bottom: 2rem;

        li {
          font-size: 1.1rem;
          line-height: 1.6;
          color: #666;
          margin-bottom: 1rem;
        }
      }
    }
  }
`;

const TopSection = styled.div`
  width: 100%;
  height: 400px;
  background-color: #000000;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  color: white;
  position: relative;
  padding-top: 80px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 150px;
    background: linear-gradient(to top, #eef3f9, transparent);
  }
`;

const TopContent = styled.div`
  max-width: 1200px;
  padding: 0 2rem;
  margin-top: 10rem;
  animation: ${fadeIn} 1.2s ease-out;
  position: relative;
  z-index: 2;

  h1 {
    font-size: 4.5rem;
    margin-bottom: 1.5rem;
    font-weight: 800;
    color: #ffffff;
    letter-spacing: -1px;
    line-height: 1.2;

    @media (max-width: 768px) {
      font-size: 2.8rem;
    }
  }

  p {
    font-size: 1.6rem;
    margin-bottom: 3rem;
    color: #cccccc;
    font-weight: 300;
    letter-spacing: 0.5px;

    @media (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
`;

const HidroFit = () => {
  return (
    <>
      <TopSection>
        <TopContent>
          <h1>Hidrofit</h1>
          <p>Informasi Hidrofit</p>
        </TopContent>
      </TopSection>

      <HidroFitContainer>
        <HidroFitContent>
          <div className="content">
            <div>
              <div className="video">
                <iframe 
                  title="YouTube"
                  id="player"
                  width="100%"
                  src="https://www.youtube.com/embed/bsTouxX3-P4"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                >
                  Your browser does not support this, try viewing it on YouTube: https://www.youtube.com/embed/bsTouxX3-P4
                </iframe>
              </div>
              <div className="video">
                <iframe 
                  title="YouTube"
                  id="player"
                  width="100%"
                  src="https://www.youtube.com/embed/HVE6bH78Cm8"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                >
                  Your browser does not support this, try viewing it on YouTube: https://www.youtube.com/embed/HVE6bH78Cm8
                </iframe>
              </div>
              <div className="content-page">
                <p>
                  Sebaik baiknya AIR afalah air yang ALLAH turunkan dari langit, yaitu HIDROGEN zat yang maha dahsyat sebagai ANTI OKSIDAN TERBAIK dapat menyebuhkan berbagai macam penyakit dengan uji di ikan dn tikus berikut videonya kita bisa simak :
                </p>
              </div>
              <div className="video">
                <iframe 
                  title="YouTube"
                  id="player"
                  width="100%"
                  src="https://www.youtube.com/embed/71PQn5-UniQ"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                >
                  Your browser does not support this, try viewing it on YouTube: https://www.youtube.com/embed/71PQn5-UniQ
                </iframe>
              </div>
            </div>
          </div>
        </HidroFitContent>
      </HidroFitContainer>
    </>
  );
};

export default React.memo(HidroFit);
