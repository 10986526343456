import React, { useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { FaCheck } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import h2Image from '../assets/images/h2.png';
import fuelWaterHybridImage from '../assets/images/fuel-water-hybrid.png';
import hidrogenPastaImage from '../assets/images/hidrogen-pasta.png';
import hidrofitImage from '../assets/images/hidrofit.png';
import tiis from '../assets/images/tiis.png';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const HomeContainer = styled.div`
  width: 100%;
  background-color: #eef3f9;
  color: #333333;
`;

const HeroSection = styled.section`
  height: 100vh;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 150px;
    background: linear-gradient(to top, #eef3f9, transparent);
  }
`;

const HeroContent = styled.div`
  width: 100%;
  padding: 0 2rem;
  animation: ${fadeIn} 1.2s ease-out;
  position: relative;
  z-index: 2;

  h1 {
    font-size: 4.5rem;
    margin-bottom: 1.5rem;
    font-weight: 800;
    color: #ffffff;
    letter-spacing: -1px;
    line-height: 1.2;

    @media (max-width: 768px) {
      font-size: 2.8rem;
    }
  }

  p {
    font-size: 1.6rem;
    margin-bottom: 3rem;
    color: #cccccc;
    font-weight: 300;
    letter-spacing: 0.5px;

    @media (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
`;

const Button = styled.button`
  padding: 1.4rem 3.5rem;
  font-size: 1.2rem;
  background-color: transparent;
  color: white;
  border: 2px solid rgba(255, 255, 255, 0.8);
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;

  &:hover {
    background-color: #ffffff;
    color: #000000;
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }

  &:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }
`;

const ContentWrapper = styled.div`
  // width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;

  @media (max-width: 768px) {
    padding: 0 1rem;
  }
`;

const ContentBlockquote = styled(ContentWrapper)`
  margin: 0 auto;
  padding: 4rem 3rem;
  background: #eef3f9;
  border-left: 5px solid #007bff;

  blockquote {
    margin: 0;
    
    p {
      font-size: 1.2rem;
      line-height: 1.8;
      margin-bottom: 1.5rem;
      color: #333333;
    }

    cite {
      font-style: italic;
      color: #666666;
      font-size: 1.1rem;
    }
  }
`;

const ContentPage = styled(ContentWrapper)`
  margin: 0 auto;
  padding: 4rem 3rem;
  background: #eef3f9;
  position: relative;

  .video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    max-width: 100%;
    margin: 2rem auto;
    border-radius: 12px;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
      border-radius: 12px;
    }
  }

  .video-wrapper {
    position: relative;
    width: 100%;
    height: 400px; // You can adjust this height as needed
    margin: 2rem auto;
    border-radius: 12px;
    overflow: hidden;

    iframe {
      border: none;
      border-radius: 12px;
    }
  }

  h1 {
    text-align: center;
    margin-bottom: 3rem;
    font-size: 3rem;
    color: #333333;
    font-weight: 700;
    letter-spacing: -1px;
  }

  h2 {
    margin: 3rem 0 2rem;
    font-size: 2.2rem;
    color: #333333;
    text-align: center;
    position: relative;

    &::after {
      content: '';
      display: block;
      width: 60px;
      height: 2px;
      background: #007bff;
      margin: 1rem auto;
    }

    strong {
      color: #007bff;
    }
  }

  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    margin-bottom: 3rem;
    align-items: center;
    background: white;
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      gap: 2rem;
    }

    .left {
      position: relative;
      background-color: white;
      padding: 2rem;
      border-radius: 10px;
      
      img {
        width: 100%;
        height: auto;
        max-width: 450px;
        margin: 0 auto;
        display: block;
        transition: all 0.3s ease;
        mix-blend-mode: multiply;
        filter: brightness(1) contrast(1.1);
        
        &:hover {
          transform: scale(1.05);
          filter: brightness(1.1) contrast(1.2);
        }
      }
    }

    .right p {
      font-size: 1.2rem;
      line-height: 1.8;
      color: #333333;
    }
  }
`;

const Accordion = styled.div`
  margin: 3rem auto 7rem;
  // max-width: 1200px;
  padding: 0 2rem;
  background: #eef3f9;

  @media (max-width: 768px) {
    padding: 0 1rem;
  }

  details {
    margin-bottom: 1.5rem;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    background: transparent;
    transition: all 0.3s ease;
    overflow: hidden;

    &:hover {
      box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
      border-color: #ffffff;
    }

    &[open] {
      box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    }

    summary {
      cursor: pointer;
      padding: 1.5rem;
      user-select: none;
      list-style: none;
      display: flex;
      align-items: center;
      background: #007bff;
      transition: background-color 0.3s ease;
      
      &::before {
        content: '+';
        margin-right: 1rem;
        font-size: 1.5rem;
        color: #ffffff;
        transition: transform 0.3s ease;
      }

      &::-webkit-details-marker {
        display: none;
      }
      
      h3 {
        display: inline;
        color: #ffffff;
        font-size: 1.3rem;
        transition: color 0.3s ease;
      }

      &:hover {
        background: #0056b3;
        
        h3 {
          color: #ffffff;
        }
      }
    }

    &[open] summary::before {
      transform: rotate(45deg);
    }

    div {
      padding: 1.5rem;
      animation: ${fadeIn} 0.5s ease;
      background-color: #000000;

      center {
        margin: 1.5rem 0;
      }

      .logo-img {
        max-width: 220px;
        height: auto;
        transition: transform 0.3s ease;
        border-radius: 10px;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);

        &:hover {
          transform: scale(1.05);
        }
      }

      p {
        color: #ffffff;
        line-height: 1.8;
        font-size: 1.1rem;
        margin-bottom: 1rem;
      }

      ol {
        padding-left: 1.5rem;
        color: #ffffff;
        
        li {
          margin-bottom: 1rem;
          color: #ffffff;
          
          strong {
            color: #007bff;
          }
        }
      }
    }
  }
`;

const UpcomingProduct = styled.section`
  width: 100%;
  margin: 0 auto;
  padding: 4rem 3rem;
  background: #eef3f9;
  position: relative;

  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

const ProductTitle = styled.h2`
  width: 100%;
  margin: 3rem 0 2rem;
  font-size: 2.2rem;
  color: #333333;
  text-align: center;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 60px;
    height: 2px;
    background: #007bff;
    margin: 1rem auto;
  }

  strong {
    color: #007bff;
  }
`;

const ProductCard = styled.article`
  width: 100%;
  background: white;
  border-radius: 15px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin: 0 auto;
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    padding: 1.5rem;
  }
`;

const Figure = styled.figure`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
`;

const ProductImage = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
`;

const ProductDescription = styled.figcaption`
  text-align: center;
  line-height: 1.6;
  color: #666;

  p {
    margin-bottom: 1.5rem;
  }
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  text-align: left;
`;

const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
`;

const Highlight = styled.span`
  font-weight: bold;
  color: #333;
`;

const Badge = styled.span`
  display: block;
  color: green;
`;

const PastaModule = styled(ContentWrapper)`
  width: 100%;
  background: linear-gradient(145deg, #8B0000, #800000);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 8px;
  transition: all 0.3s ease;
  
  text-align: center;
  margin: 3rem auto 0;
  font-size: 1.3rem;
  line-height: 2;
  color: #ffffff;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  transform: translateY(0);

  &:hover {
    background: linear-gradient(145deg, #990000, #8B0000);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    will-change: transform, box-shadow;
  }

  br {
    content: '';
    display: block;
    margin: 0.5rem 0;
  }
`;

const ProdukUnggulan = styled.section`
  width: 100%;
  background: white;
  border-radius: 15px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin: 0 auto;
  text-align: center;

  h2 {
    color: #333;
    font-size: 2.5rem;
    margin-bottom: 1rem;
    // text-transform: uppercase;
  }

  h3 {
    color: #666;
    font-size: 1.5rem;
    margin-bottom: 2rem;
    line-height: 1.4;
  }

  iframe {
    max-width: 100%;
    margin: 2rem 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  p {
    color: #555;
    font-size: 1.1rem;
    line-height: 1.6;
    // max-width: 800px;
    margin: 1.5rem auto;
    text-align: justify;
  }

  /* Responsive design */
  @media (max-width: 768px) {
    padding: 1rem;

    h2 {
      font-size: 2rem;
    }

    h3 {
      font-size: 1.2rem;
    }

    iframe {
      height: 240px;
    }

    p {
      font-size: 1rem;
      padding: 0 1rem;
    }
  }

  @media (max-width: 480px) {
    h2 {
      font-size: 1.75rem;
    }

    iframe {
      height: 200px;
    }
  }
`;

const Home = () => {
  const contentPageRef = useRef(null);

  const scrollToContent = () => {
    contentPageRef.current?.scrollIntoView({ 
      behavior: 'smooth',
      block: 'start'
    });
  };
  return (
    <HomeContainer>
      <HeroSection>
        <HeroContent>
          <h1>Leading the Future of Hydrogen Energy</h1>
          <p>Innovative solutions for a sustainable tomorrow</p>
          <Button onClick={scrollToContent}>Learn More</Button>
        </HeroContent>
      </HeroSection>

      <ContentBlockquote>
        <blockquote>
          <p>Air kaya hidrogen tidak hanya memiliki ribuan testimoni, tapi merupakan SATU - SATUNYA produk kesehatan yang didukung ratusan jurnal kedokteran terkemuka di dunia. Diteliti lebih dari 1600 peneliti terkemuka, lebih dari 1000 artikel ilmiah di bidang kesehatan, dapat menyembuhkan lebih dari 170 penyakit pada organ tubuh manusia, dan terbukti aman dikonsumsi oleh semua kalangan lebih dari 50 tahun.</p>
          <cite>- Molecular Hydrogen Institute</cite>
        </blockquote>
      </ContentBlockquote>

      <ContentPage ref={contentPageRef}>
        <h1>HIDROGEN</h1>
        <div className="container">
          <div className="left">
            <img src={h2Image} alt="H2" title="H2" />
          </div>
          <div className="right">
            <p>Minum air kaya hidrogen adalah bagian dari pola hidup sehat seperti DIET SEIMBANG, OLAHRAGA RUTIN, dan MINUM OBAT / SUPLEMEN teratur. Air hidrogen TIDAK MENGGANTIKAN, namun SALING MELENGKAPI dengan cara hidup sehat yang lain. Setiap orang, dalam hubungannya dengan AIR yang MEMANG HARUS DIMINUM SETIAP HARI hanya memiliki dua pilihan, MINUM AIR MINERAL BIASA atau MINUM AIR HIDROGEN. Air kaya hidrogen jauh lebih baik dibanding air biasa, bermanfaat pada lebih dari 170 penyakit, dengan manfaat utama untuk PROGRAM PENCEGAHAN PENYAKIT bagi SELURUH KELUARGA dalam jangka panjang, bankan SEUMUR HIDUP.</p>
          </div>
        </div>

        <UpcomingProduct>
        <ProductTitle>ANTI OKSIDAN <br /><strong>TERBAIK!</strong></ProductTitle>
        <ProductCard>
          <Figure>
            <FeatureList>
              <FeatureItem>
                <FaCheck color="#007bff" /> Bersifat selektif (hanya aktif apabila radikal bebas berbahaya)
              </FeatureItem>
              <FeatureItem>
                <FaCheck color="#007bff" /> Mengaktifkan sistem anti oksidan endogen & eksogen
              </FeatureItem>
              <FeatureItem>
                <FaCheck color="#007bff" /> Hiporeaktif & tidak berpengarh buruk bagi tubuh
              </FeatureItem>
              <FeatureItem>
                <FaCheck color="#007bff" /> Toksitas rendah & ditoleransi tubuh lebih baik
              </FeatureItem>
              <FeatureItem>
                <FaCheck color="#007bff" /> Ukuran sangat kecil, lebih cepat & mudah menembus sel
              </FeatureItem>
              <FeatureItem>
                <FaCheck color="#007bff" /> Memiliki manfaat lain selain sebagai anti oksidan
              </FeatureItem>
              <FeatureItem>
                <FaCheck color="#007bff" /> Dapat diiproduksi secara mandiri (lebih praktis, ekonomis)
              </FeatureItem>
            </FeatureList>
          </Figure>
        </ProductCard>
      </UpcomingProduct>

        <h2>Produk yang telah dikembangkan</h2>
        <Accordion>
          <details>
            <summary><h3>Fuel Water Hybrid</h3></summary>
            <div>
              <center>
                <img src={fuelWaterHybridImage} alt="Fuel Water Hybrid" title="Fuel Water Hybrid" className="logo-img" />
              </center>
              <p>Sebuah efisiensi pemakaian bahan bakar kendaraan bensin maupun solar dengan berbasis hidrogen sehingga manfaat yang didapat adalah kendaraan berperformance lebih meningkat, efisiensi bahan bakar, dan jaminan lulus uji emisi gas buang dengan oktan 130.</p>
              
              <Link 
                to="/fuel-water-hybrid" 
                className="info-link"
                aria-label="Learn more about fuel water hybrid"
              >
                <span>Info selengkapnya</span>
                <svg 
                  className="arrow-icon" 
                  width="16" 
                  height="16" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="currentColor" 
                  strokeWidth="2"
                  strokeLinecap="round" 
                  strokeLinejoin="round"
                >
                  <line x1="5" y1="12" x2="19" y2="12" />
                  <polyline points="12 5 19 12 12 19" />
                </svg>
              </Link>
            </div>
          </details>

          <details>
            <summary><h3>HidrogenPASTA</h3></summary>
            <div>
              <center>
                <img 
                  src={hidrogenPastaImage} 
                  alt="Hidrogen PASTA" 
                  title="Hidrogen PASTA" 
                  className="logo-img" 
                />
              </center>
              <p>
                Sebuah rekayasa teknologi yang berbasis hidrogen untuk menekan pemakaian beban 
                rekening pembayaran listrik industri dengan satuan panel RST dan berbasis 
                manajemen distribusi panel (MDP). Sehingga listrik yang terpakai dari beban 
                ke meteran itu bukan mencuri atau menjadi efisiensi hingga 50%.
              </p>
              
              <Link 
                to="/hidrogenpasta" 
                className="info-link"
                aria-label="Learn more about HidrogenPasta"
              >
                <span>Info selengkapnya</span>
                <svg 
                  className="arrow-icon" 
                  width="16" 
                  height="16" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="currentColor" 
                  strokeWidth="2"
                  strokeLinecap="round" 
                  strokeLinejoin="round"
                >
                  <line x1="5" y1="12" x2="19" y2="12" />
                  <polyline points="12 5 19 12 12 19" />
                </svg>
              </Link>
            </div>
          </details>

          <details>
            <summary><h3>Hidrofit</h3></summary>
            <div>
              <center>
                <img src={hidrofitImage} alt="Hidrofit Plus" title="Hidrofit Plus" className="logo-img" />
              </center>
              <p>Adalah hidrogen untuk kesehatan, seperti kita ketahui hidrogen adalah anti oksidan terbaik selain vitamin, buah-buahan dan sayuran. Dikarenakan vitamin, buah-buahan dan sayuran harus diolah terlebih dahulu melalui tubuh, berbeda dengan hidrogen langsung dicerna melalui air hidrogen yang terbentuk dari pelarut kandungan terdalam hidrogen.</p>
              <ol>
                <li><strong>Terapi Uap</strong> untuk kesehatan.</li>
                <li><strong>Terapi Mandi</strong> dilakukan pada bilasan mandi terakhir tujuannya untuk regenerasi kulit dan melawan detox (penuaan), terutama untuk yang rambutnya botak.</li>
                <li><strong>Terapi Minum</strong> dengan alat hidrofit terbaru, hidrogen yang diminum menjadi anti oksidan terbaik dapat menyembuhkan 170 penyakit. telah diurai oleh para dokter dan dukungan jurnal kedokteran seluruh dunia.</li>
              </ol>
              
              <Link 
                to="/hidrofit" 
                className="info-link"
                aria-label="Learn more about fuel water hidrofit plus"
              >
                <span>Info selengkapnya</span>
                <svg 
                  className="arrow-icon" 
                  width="16" 
                  height="16" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="currentColor" 
                  strokeWidth="2"
                  strokeLinecap="round" 
                  strokeLinejoin="round"
                >
                  <line x1="5" y1="12" x2="19" y2="12" />
                  <polyline points="12 5 19 12 12 19" />
                </svg>
              </Link>
            </div>
          </details>
        </Accordion>

        <h2>Produk UNGGULAN</h2>
        <ProdukUnggulan>
          <h3>
            WATER TREATMENT PLANT: MERUBAH AIR LAUT MENJADI AIR SIAP MINUM!
          </h3>
          <div className="video-container">
            <iframe 
              title="YouTube Video Player"
              src="https://www.youtube.com/embed/_VG60lZFEgk"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              loading="lazy"
            />
          </div>
          <p>
          Dengan Teknologi mikro 0,1000 membran bahan dasar hidrogen untuk memisahkan kadar zat garam yang terlarut dalam air hingga dengan pH netral di tingkatkan jet ozon tanpa bakteri menjadikan TDS air laut 5500 menjadi TDS max. 5 sudah layak untuk langsung di minum.
          </p>
        </ProdukUnggulan>


        <UpcomingProduct>
          <ProductTitle>
            Produk TERBARU! <Badge>coming soon</Badge>
          </ProductTitle>
          <ProductCard>
            <Figure>
              <ProductImage
                src={tiis} // Make sure to import this image
                alt="TiiS cooling system product"
                title="TiiS"
                loading="lazy"
                width="300"
                height="200"
              />
              <div className="video-wrapper">
                <iframe 
                  title="YouTube Video Player"
                  src="https://www.youtube.com/embed/0VKXUc_GPho"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  loading="lazy"
                  width="100%"
                  height="100%"
                />
              </div>
              <ProductDescription>
                Teknologi terbarukan mikro membran NANO<br /> 
                Menghasilkan AIR MURNI<br />
                Air alamai sangat berkwalitas Berbasis HIDROGEN TERMAL<br />
                Menjadikan mesin & Radiator Selalu Lebih DINGIN tahan lama Tidak berlumut & berkarat
              </ProductDescription>
              <FeatureList>
                <FeatureItem>
                  <FaCheck color="#007bff" /> Tanpa <Highlight>PEWARNA</Highlight>
                </FeatureItem>
                <FeatureItem>
                  <FaCheck color="#007bff" /> Tanpa <Highlight>KIMIA BERBAHAYA</Highlight>
                </FeatureItem>
                <FeatureItem>
                  <FaCheck color="#007bff" /> Tanpa <Highlight>LOGAM MINERAL</Highlight>
                </FeatureItem>
                <FeatureItem>
                  <FaCheck color="#007bff" /> Tanpa <Highlight>partikel jel</Highlight>
                </FeatureItem>
                <FeatureItem>
                  <FaCheck color="#007bff" /> Tanpa <Highlight>masa pakai</Highlight>
                </FeatureItem>
              </FeatureList>
            </Figure>
          </ProductCard>
        </UpcomingProduct>

        <h2>Jaminan</h2>
        <center>
          <img 
            src={hidrogenPastaImage} 
            alt="Hidrogen PASTA" 
            title="Hidrogen PASTA"
            loading="lazy"
            style={{ backgroundColor: 'black', width: 'auto' }}
          />
        </center>

        <PastaModule>
          UANG KEMBALI JIKA<br />
          TIDAK TERBUKTI!<br />
          Setelah alat terpasang langsung dapat dirasakan penghematan BEBAN LISTRIK minimal 10% langsung dapat di rasakan & bertahap bertingkat berlanjut penghematan terus bertambah seiring pemakaian hingga lebih dari 30%.<br />
          BUKTIKAN!
        </PastaModule>
      </ContentPage>
    </HomeContainer>
  );
};

export default Home;
