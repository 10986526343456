import React, { useRef, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import audioFile1 from '../assets/audio/testiAudio1.m4a';
import audioFile2 from '../assets/audio/testiAudio2.m4a';
import audioFile3 from '../assets/audio/testiAudio3.m4a';
import audioFile4 from '../assets/audio/testiAudio4.m4a';
import audioFile5 from '../assets/audio/testiAudio5.m4a';
import audioFile6 from '../assets/audio/testiAudio6.m4a';
import audioFile7 from '../assets/audio/testiAudio7.m4a';
import audioFile8 from '../assets/audio/testiAudio8.m4a';
import audioFile9 from '../assets/audio/testiAudio9.m4a';
import audioFile10 from '../assets/audio/testiAudio10.m4a';
import audioFile11 from '../assets/audio/testiAudio11.m4a';
import audioFile12 from '../assets/audio/testiAudio12.m4a';
import audioFile13 from '../assets/audio/testiAudio13.m4a';
import audioFile14 from '../assets/audio/testiAudio14.m4a';
import audioFile15 from '../assets/audio/testiAudio15.m4a';
import audioFile16 from '../assets/audio/testiAudio16.m4a';
import audioFile17 from '../assets/audio/testiAudio17.m4a';
import audioFile18 from '../assets/audio/testiAudio18.m4a';
import audioFile19 from '../assets/audio/testiAudio19.m4a';
import audioFile20 from '../assets/audio/testiAudio20.m4a';

// Animation keyframes
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const FuelWaterHybridContainer = styled.div`
  width: 100%;
  background-color: #eef3f9;
`;

const FuelWaterHybridContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  animation: ${fadeIn} 1s ease-out;

  h3 {
    font-size: 2rem;
    margin-bottom: 2rem;
    color: #333;
    position: relative;

    &::after {
      content: '';
      display: block;
      width: 60px;
      height: 3px;
      background: #007bff;
      margin-top: 1rem;
    }
  }

  .content {
    background-color: white;
    padding: 2.5rem;
    border-radius: 15px;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
    
    .video {
      margin-bottom: 2rem;
      position: relative;
      padding-bottom: 56.25%; // 16:9 aspect ratio
      height: 0;
      overflow: hidden;
      border-radius: 10px;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
      }
    }

    .content-page {
      h2 {
        font-size: 2.2rem;
        color: #333;
        margin-bottom: 1.5rem;
      }

      h3 {
        font-size: 1.8rem;
        color: #444;
        margin: 2rem 0 1rem;
      }

      h4 {
        font-size: 1.5rem;
        color: #555;
        margin: 1.5rem 0 1rem;
      }

      p {
        font-size: 1.1rem;
        line-height: 1.8;
        color: #666;
        margin-bottom: 1.5rem;
      }

      ol {
        padding-left: 1.5rem;
        margin-bottom: 2rem;

        li {
          font-size: 1.1rem;
          line-height: 1.6;
          color: #666;
          margin-bottom: 1rem;
        }
      }
    }
  }
`;

const TopSection = styled.div`
  width: 100%;
  height: 400px;
  background-color: #000000;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  color: white;
  position: relative;
  padding-top: 80px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 150px;
    background: linear-gradient(to top, #eef3f9, transparent);
  }
`;

const TopContent = styled.div`
  max-width: 1200px;
  padding: 0 2rem;
  margin-top: 10rem;
  animation: ${fadeIn} 1.2s ease-out;
  position: relative;
  z-index: 2;

  h1 {
    font-size: 4.5rem;
    margin-bottom: 1.5rem;
    font-weight: 800;
    color: #ffffff;
    letter-spacing: -1px;
    line-height: 1.2;

    @media (max-width: 768px) {
      font-size: 2.8rem;
    }
  }

  p {
    font-size: 1.6rem;
    margin-bottom: 3rem;
    color: #cccccc;
    font-weight: 300;
    letter-spacing: 0.5px;

    @media (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
`;

const AudioGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
  padding: 1rem;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

const AudioWrapper = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;

  &:hover {
    transform: translateY(-2px);
  }

  audio {
    width: 100%;
    height: 40px;
    
    &::-webkit-media-controls-panel {
      background-color: #f8f9fa;
    }
    
    &::-webkit-media-controls-play-button {
      background-color: #007bff;
      border-radius: 50%;
    }
    
    &::-webkit-media-controls-current-time-display,
    &::-webkit-media-controls-time-remaining-display {
      color: #333;
    }
    
    &::-webkit-media-controls-timeline {
      background-color: #dee2e6;
    }
    
    &::-webkit-media-controls-volume-slider {
      background-color: #007bff;
    }
  }
`;

const AudioPlayer = ({ audioFiles }) => {
  const audioRefs = useRef([]);

  useEffect(() => {
    audioRefs.current = audioRefs.current.slice(0, audioFiles.length);
  }, [audioFiles]);

  const handlePlay = (playingIndex) => {
    audioRefs.current.forEach((audioRef, index) => {
      if (index !== playingIndex && audioRef && !audioRef.paused) {
        audioRef.pause();
      }
    });
  };

  return (
    <AudioGrid>
      {audioFiles.map((audioFile, index) => (
        <AudioWrapper key={index}>
          <audio
            controls
            ref={el => audioRefs.current[index] = el}
            onPlay={() => handlePlay(index)}
          >
            <source src={audioFile} type="audio/mp4" />
            Your browser does not support the audio element.
          </audio>
        </AudioWrapper>
      ))}
    </AudioGrid>
  );
};

const FuelWaterHybrid = () => {
  const audioFiles = [
    audioFile1, audioFile2, audioFile3, audioFile4, audioFile5,
    audioFile6, audioFile7, audioFile8, audioFile9, audioFile10,
    audioFile11, audioFile12, audioFile13, audioFile14, audioFile15,
    audioFile16, audioFile17, audioFile18, audioFile19, audioFile20
  ];

  return (
    <>
      <TopSection>
        <TopContent>
          <h1>Fuel Water Hybrid</h1>
          <p>Informasi Fuel Water Hybrid</p>
        </TopContent>
      </TopSection>

      <FuelWaterHybridContainer>
        <FuelWaterHybridContent>
          <div className="content">
            <div>
              <div className="video">
                <iframe 
                  title="YouTube"
                  id="player"
                  width="100%"
                  src="https://www.youtube.com/embed/KvY8W8gJgYM"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                >
                  Your browser does not support this, try viewing it on YouTube: https://www.youtube.com/embed/KvY8W8gJgYM
                </iframe>
              </div>
              <div className="content-page">
                <p>
                  Sebuah alat reaktor yang menghasilkan GAS HIDROGEN masuk ke ruang bakar yang berfungsi menambah oktan bahan bakar bersumber dari AIR MURNI menjadi oktan 130 yang sangat BESAR hingga power bertambah 2 kali lipat. Dan Pastinya! BBM hemat lebih dari 30%. Asap buangan menjadi 0 atau TANPA EMISI (sesuai program pemerintah kedepan). Alat ini di pasang di belakang mobil dan tidak merubah atau mengintervensi mesin mobil (bensin atau diesel). Mesin dingin dan sangat aman karena HIDROGEN GAS yang bersumber dari air.
                </p>
                <h3>Biaya Pembuatan Alat Reaktor</h3>
                <h4>Motor dan Mobil</h4>
                <ol>
                  <li>100-250 CC Rp3,5 juta</li>
                  <li>1.000 - 2.500 CC Rp7,5 juta</li>
                  <li>3.000 CC up Rp15 juta</li>
                  <li>3.000 - 5.000 Rp18 juta</li>
                  <li>Alat berat Rp35 juta</li>
                  <li>Multi Rp12,5 juta</li>
                </ol>
                <p>
                  Reaktor khusus untuk di mobil sekelas FORTUNER, dan juga berfungsi untuk alat terapi kesehatan.
                </p>
                <h2>HIDROFIT Plus: Rp15 juta</h2>
                <p>
                  Alat terapi kesehatan keluarga berfungsi untuk terapi dihisap, diminum dan untuk mandi hidrogen.
                </p>
                <p>
                  TURBO Rp15,5 juta untuk pemakaian mulai 1.000 - 3.500 CC plus terapi kesehatan keluarga.
                </p>
                <h2>Pemesanan</h2>
                <h3>Proses</h3>
                <p>
                  Pembuatan Alat Reaktor dengan DP 50% dan PELUNASAN dilakukan saat akan pemasangan/pengiriman. Proses pabrikasi 10 hari kerja dengan instalasi pemasangan 1 jam (biaya sudah termasuk pemasangan).
                </p>
                <p>
                  BONUS BANTU JUAL 10 unit, GRATIS 1 unit.
                </p>
                <h2>Testimoni Pengguna</h2>
                <AudioPlayer audioFiles={audioFiles} />
              </div>
            </div>
          </div>
        </FuelWaterHybridContent>
      </FuelWaterHybridContainer>
    </>
  );
};

export default React.memo(FuelWaterHybrid);
