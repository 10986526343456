import React from 'react';
import styled, { keyframes } from 'styled-components';

// Animation keyframes
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const TopSection = styled.div`
  width: 100%;
  height: 400px;
  background-color: #000000;
  display: flex;
  align-items: flex-start; // Changed from center to flex-start
  justify-content: center;
  text-align: center;
  color: white;
  position: relative;
  padding-top: 80px; // Added padding-top

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 150px;
    background: linear-gradient(to top, #eef3f9, transparent);
  }
`;

const TopContent = styled.div`
  max-width: 1200px;
  padding: 0 2rem;
  margin-top: 10rem; // Specific margin for content
  animation: ${fadeIn} 1.2s ease-out;
  position: relative;
  z-index: 2;

  h1 {
    font-size: 4.5rem;
    margin-bottom: 1.5rem;
    font-weight: 800;
    color: #ffffff;
    letter-spacing: -1px;
    line-height: 1.2;

    @media (max-width: 768px) {
      font-size: 2.8rem;
    }
  }

  p {
    font-size: 1.6rem;
    margin-bottom: 3rem;
    color: #cccccc;
    font-weight: 300;
    letter-spacing: 0.5px;

    @media (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
`;

const KontakContainer = styled.div`
  width: 100%;
  background-color: #eef3f9;
`;

const KontakContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  animation: ${fadeIn} 1s ease-out;
`;

const KontakInfo = styled.div`
  background-color: white;
  padding: 2.5rem;
  border-radius: 15px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }

  h3 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: #333;
    position: relative;

    &::after {
      content: '';
      display: block;
      width: 60px;
      height: 3px;
      background: #007bff;
      margin-top: 1rem;
    }
  }

  p {
    font-size: 1.1rem;
    line-height: 1.8;
    color: #666;
    margin-bottom: 2rem;
  }

  h4 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    color: #333;
  }

  ul {
    list-style: none;
    padding: 0;
    
    li {
      margin-bottom: 1.2rem;
      font-size: 1.1rem;
      line-height: 1.6;
      color: #555;
      display: flex;
      align-items: flex-start;
      
      strong {
        color: #007bff;
        min-width: 80px;
        margin-right: 1rem;
      }
    }
  }
`;

const Kontak = () => {
  return (
    <>
      <TopSection>
        <TopContent>
          <h1>Kontak</h1>
          <p>Jangan ragu untuk menghubungi kami jika ada pertanyaan.</p>
        </TopContent>
      </TopSection>

      <KontakContainer>
        <KontakContent>
          <KontakInfo>
            <h3>Detil:</h3>
            <ul>
              <li><strong>HP:</strong> +62 888 8999 966</li>
              <li>
                <strong>Alamat:</strong> Jl. Raya Narogong KM. 11 No. 8/9, 
                Limus Nunggal, Kec. Cileungsi, Kabupaten Bogor, Jawa Barat 16820
              </li>
              <li><strong>Email:</strong> passakti@yahoo.com</li>
            </ul>
          </KontakInfo>
        </KontakContent>
      </KontakContainer>
    </>
  );
};

export default React.memo(Kontak);
