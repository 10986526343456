import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: #333;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  button {
    cursor: pointer;
  }

  .info-link {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 12px 24px;
    color: #ffffff;
    background-color: #007bff;
    text-decoration: none;
    font-weight: 500;
    font-size: 1rem;
    position: relative;
    transition: all 0.3s ease;
    border-radius: 50px;
    border: 2px solid #007bff;
    overflow: hidden;
    box-shadow: 0 4px 15px rgba(0, 123, 255, 0.2);
    /* Add these lines for centering */
    display: block;
    margin: 20px auto;
    width: fit-content;
  }

  .info-link span {
    position: relative;
    z-index: 1;
    transition: all 0.3s ease;
  }

  .info-link .arrow-icon {
    transition: all 0.3s ease;
    position: relative;
    z-index: 1;
    stroke: #ffffff;
  }

  .info-link::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
  }

  .info-link:hover {
    box-shadow: 0 6px 20px rgba(0, 123, 255, 0.3);
    transform: translateY(-2px);
  }

  .info-link:hover::before {
    transform: translateX(0);
  }

  .info-link:hover span {
    color: #007bff;
  }

  .info-link:hover .arrow-icon {
    transform: translateX(5px);
    stroke: #007bff;
  }

  .info-link:active {
    transform: translateY(0);
    box-shadow: 0 2px 10px rgba(0, 123, 255, 0.2);
  }

  /* Focus styles for accessibility */
  .info-link:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.3);
  }

  /* Dark mode support */
  @media (prefers-color-scheme: dark) {
    .info-link {
      background-color: #0056b3;
      border-color: #0056b3;
    }

    .info-link:hover span {
      color: #0056b3;
    }

    .info-link:hover .arrow-icon {
      stroke: #0056b3;
    }
  }

  /* Responsive adjustments */
  @media (max-width: 768px) {
    .info-link {
      padding: 10px 20px;
      font-size: 0.9rem;
    }
  }

  .content {
  padding: 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h2 {
  color: #333;
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
  text-align: center;
}

.pdf-options {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 1.5rem;
}

.pdf-button {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.3s ease;
  border: 2px solid #007bff;
}

.pdf-button:hover {
  background-color: #0056b3;
  border-color: #0056b3;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.2);
}

.pdf-button:active {
  transform: translateY(0);
}

.download-icon,
.view-icon {
  transition: transform 0.3s ease;
}

.pdf-button:hover .download-icon {
  transform: translateY(2px);
}

.pdf-button:hover .view-icon {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .pdf-options {
    flex-direction: column;
  }
  
  .pdf-button {
    width: 100%;
    justify-content: center;
  }
}


`;

export default GlobalStyles;
