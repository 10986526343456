import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import FuelWaterHybrid from './pages/FuelWaterHybrid';
import HidrogenPasta from './pages/HidrogenPasta';
import HidroFit from './pages/HidroFit';
import Kontak from './pages/Kontak';
import GlobalStyles from './assets/styles/GlobalStyles';
import styled from 'styled-components';

// Styled component for the main app container
const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

// Styled component for the main content area
const MainContent = styled.main`
  flex: 1;
  width: 100%;
`;

const App = () => {
  return (
    <Router>
      <AppContainer>
        <GlobalStyles />
        <Header />
        <MainContent>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/fuel-water-hybrid" element={<FuelWaterHybrid />} />
            <Route path="/hidrogenpasta" element={<HidrogenPasta />} />
            <Route path="/hidrofit" element={<HidroFit />} />
            <Route path="/kontak" element={<Kontak />} />
          </Routes>
        </MainContent>
        <Footer />
      </AppContainer>
    </Router>
  );
};

export default App;
